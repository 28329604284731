@import url(https://fonts.googleapis.com/css2?family=Cabin&display=swap);
.bg-white {
    background-color: white !important
}

.margin{
  margin-bottom: 55px;
}

.title{
    font-size: 2.2rem; 
}

.image{
    max-height: 721px;
}


.btn-dark-blue {
  color: white !important;
  background-color: rgba(67, 130, 155, 0.9) !important;
  border-color: rgba(67, 130, 155, 0.9) !important; 
}

.btn-dark-blue:hover {
  color: white !important;
  background-color: rgba(67, 130, 155, 0.8) !important;
  border-color: rgba(67, 130, 155, 0.8) !important;
}

.footer{
  bottom: 0;
} 

.bg-white {
  background-color: white !important
}

.contact-icon-linkedin a{
  color: rgba(10, 102, 104, 1);
}

.contact-icon-linkedin:hover  a{
  color: rgba(10, 102, 104, 0.6);
}

.contact-icon-facebook a{
  color: rgba(72, 103, 170, 1);
}

.contact-icon-facebook:hover a{
  color: rgba(72, 103, 170, 0.6);
}

.contact-icon-envelope a{
  color: rgb(230, 126, 28, 1);
}

.contact-icon-envelope:hover a{
  color: rgb(230, 126, 28, 0.6);
}

.contact-icon-copyright {
  color: rgb(114, 100, 89, 1);
}

.contact-icon-copyright:hover {
  color: rgb(114, 100, 89, 0.6);
  cursor: pointer;
}

.mentions-titre {
  font-weight: 900;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 50px;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  height: 100%;
  text-align: center;
  font-family: 'Cabin', condensed;
  overflow: hidden;
}

.text-title1{
  font-size: 1.5rem;
}
.text-title2{
  font-size: 1.25rem;
}

.transform:hover{
  transform: scale(1.03);
}

