.footer{
  bottom: 0;
} 

.bg-white {
  background-color: white !important
}

.contact-icon-linkedin a{
  color: rgba(10, 102, 104, 1);
}

.contact-icon-linkedin:hover  a{
  color: rgba(10, 102, 104, 0.6);
}

.contact-icon-facebook a{
  color: rgba(72, 103, 170, 1);
}

.contact-icon-facebook:hover a{
  color: rgba(72, 103, 170, 0.6);
}

.contact-icon-envelope a{
  color: rgb(230, 126, 28, 1);
}

.contact-icon-envelope:hover a{
  color: rgb(230, 126, 28, 0.6);
}

.contact-icon-copyright {
  color: rgb(114, 100, 89, 1);
}

.contact-icon-copyright:hover {
  color: rgb(114, 100, 89, 0.6);
  cursor: pointer;
}

.mentions-titre {
  font-weight: 900;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 50px;
}
