
.btn-dark-blue {
  color: white !important;
  background-color: rgba(67, 130, 155, 0.9) !important;
  border-color: rgba(67, 130, 155, 0.9) !important; 
}

.btn-dark-blue:hover {
  color: white !important;
  background-color: rgba(67, 130, 155, 0.8) !important;
  border-color: rgba(67, 130, 155, 0.8) !important;
}
