@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  height: 100%;
  text-align: center;
  font-family: 'Cabin', condensed;
  overflow: hidden;
}

.text-title1{
  font-size: 1.5rem;
}
.text-title2{
  font-size: 1.25rem;
}

.transform:hover{
  transform: scale(1.03);
}
